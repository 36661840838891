import { ISupplierSignup } from 'shared/models/supplier/supplierSignup.model';
import { ICheckEmailResponse } from '../../models/account/accountCheckEmail.model';

export class AccountApiService {
  public static async signupSupplier(signupData: ISupplierSignup): Promise<void> {
    return await $fetch('/api/v1/signup/suppliers', {
      method: 'POST',
      body: signupData,
    });
  }

  public static async resendEmail(email: string): Promise<void> {
    return await $fetch('/api/v1/accounts/verify_email/resend', {
      method: 'POST',
      body: {
        email,
      },
    });
  }

  public static async validateEmail(email: string): Promise<ICheckEmailResponse> {
    return await $fetch<ICheckEmailResponse>('/api/v1/accounts/email/check_email', {
      method: 'POST',
      body: {
        email,
      },
    });
  }
}
